import { Icon } from "@iconify/react";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import type { SelectProps } from "antd";
import { Button, DatePicker, Form, Input, Select, Space, Spin, Tag } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { EditFileType, MarkerItemType, ViewFileType } from "../types";
import useFileStore from "../store";
import SweetAlert from "@components/shared/SweetAlert";

type TagRender = SelectProps["tagRender"];

type EditFileProps = {
  fileCuid: string;
};

function EditFile({ fileCuid }: EditFileProps) {
  const { viewFile, editFile, simpleListMarkers } = useFileStore();
  const [form] = Form.useForm<EditFileType>();
  const { onClose } = useContext(D3DrawerContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [extension, setExtension] = useState<string>("");
  const [markers, setMarkers] = useState<MarkerItemType[]>([]);

  useEffect(() => {
    (async () => {
      const result = await viewFile(fileCuid);
      if (result.code === 200) {
        const data = result.body as ViewFileType;
        form.setFieldsValue({
          ...data,
          markers_cuids: data.markers.map((marker) => marker.cuid),
          new_file_name: data.name.replace(data.extension.key, ""),
          expected_purge_date: data.expected_purge_date ? dayjs(data.expected_purge_date) : "",
          original_date: data.original_date ? dayjs(data.original_date) : "",
        });
        setExtension(data.extension.key);
      }
      setMarkers(await simpleListMarkers());
    })();
    setLoading(false);
  }, [viewFile, fileCuid, form]);

  const tagRender: TagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const item = markers.find((item) => item.cuid === value);
    return (
      <Tag
        color={item?.color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={async (payload: EditFileType) => {
          const { original_date, expected_purge_date, new_file_name } = payload;

          const formattedOriginalDate = original_date ? dayjs(original_date).format("YYYY-MM-DD") : "";
          const formattedPurgeDate = expected_purge_date ? dayjs(expected_purge_date).format("YYYY-MM-DD") : "";

          if (original_date && expected_purge_date && !dayjs(original_date).isBefore(dayjs(expected_purge_date))) {
            SweetAlert({
              title: "Para continuar, ajuste a data de expurgo para ser posterior à data de origem.",
              type: "error",
              timer: 4,
            });
            return;
          }

          payload.new_file_name = new_file_name + extension;
          payload.original_date = formattedOriginalDate;
          payload.expected_purge_date = formattedPurgeDate;

          const result = await editFile(fileCuid, payload);
          if (result.code === 200) {
            onClose();
          }
        }}
      >
        <Form.Item<EditFileType>
          name="new_file_name"
          label="Nome"
          rules={[{ required: true, message: "Por favor, preencha o campo nome." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<EditFileType> name="original_date" label="Data de origem">
          <DatePicker format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item<EditFileType> name="expected_purge_date" label="Data previsão expurgo">
          <DatePicker format="DD/MM/YYYY" minDate={dayjs().add(1, "day").endOf("day")} />
        </Form.Item>
        <Form.Item<EditFileType> label="Marcadores" name="markers_cuids">
          <Select
            tagRender={tagRender}
            listItemHeight={10}
            listHeight={250}
            allowClear
            removeIcon={
              <Icon
                icon="iconamoon:close-duotone"
                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
              />
            }
            menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
            optionLabelProp="dataLabel"
            showSearch
            maxTagCount="responsive"
            mode="multiple"
            size="middle"
            className="w-full truncate select-none"
            optionFilterProp="children"
            filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          >
            {markers.map(({ color, cuid, key }) => (
              <Select.Option key={cuid} value={cuid} dataFilter={`${key}`} dataLabel={`${key}`}>
                <div className="flex items-center h-8 ">
                  <Tag color={color}>{key}</Tag>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <div className="text-right">
            <Space size="small">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit">
                Atualizar
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
}
export default EditFile;
