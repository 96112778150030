import { MouseEventHandler, useContext, useState } from "react";
import { Button, Form, Input, Radio, Row, Select, Space, Spin, Tooltip } from "antd";
import { useCompanyStore } from "../../store";
import { CepType, CreateAddressCompanyType } from "../../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import D3Can from "@components/shared/D3Can";
import { listUfs } from "@utils/listUfs";
import { Icon } from "@iconify/react";
import FormItemCEP from "@components/shared/FormItemCEP";
import FormItemInputMoney from "@components/shared/FormItemInputMoney";
import SweetAlert from "@components/shared/SweetAlert";

export const CreateAddressForm = () => {
  const { createAddressCompany, getCep } = useCompanyStore();
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateAddressCompanyType>();
  const [loading, setLoading] = useState(false);

  const handleCepSearch: MouseEventHandler<HTMLElement> = async (event) => {
    setLoading(true);
    const cep = form.getFieldValue("zip_code") as string;
    if (cep && cep.length === 8) {
      const result = await getCep(cep);
      if (result.status === "success") {
        const viewCep = result.body as CepType;
        form.setFieldsValue({
          ...viewCep,
          complement: "",
        });
      }
    } else {
      SweetAlert({title:"O CEP deve conter exatamente 8 dígitos numéricos.", type: "error" })
    }

    setLoading(false);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreateAddressCompanyType) => {
            setLoading(true);
            const result = await createAddressCompany(payload);
            if (result.status === "success") {
              showMessage(result, "Endereço cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Row className="flex items-center justify-between">
            <FormItemCEP name="zip_code" required buttonProps={{ onClick: handleCepSearch }} />

            <Form.Item<CreateAddressCompanyType>
              label={
                <div className="flex items-center justify-between gap-1">
                  Endereço padrão{" "}
                  <Tooltip
                    placement="left"
                    title="O endereço padrão é adotado como referência para a emissão das notas fiscais."
                  >
                    <Icon icon="typcn:info" height={18} className="text-red-500" />
                  </Tooltip>
                </div>
              }
              name="is_default"
              initialValue={false}
            >
              <Radio.Group
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
            </Form.Item>
          </Row>

          <Form.Item<CreateAddressCompanyType>
            name="description"
            label="Descrição"
            rules={[{ required: true, message: "Por favor, insira descrição." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<CreateAddressCompanyType>
            name="street_address"
            label="Logradouro"
            rules={[{ required: true, message: "Por favor, insira logradouro." }]}
          >
            <Input />
          </Form.Item>
          <Row className="flex justify-between">
            <Form.Item<CreateAddressCompanyType>
              name="street_number"
              label="Número"
              className="w-28"
              rules={[{ required: true, message: "Por favor, insira número." }]}
            >
              <Input />
            </Form.Item>
            <Form.Item<CreateAddressCompanyType> name="complement" label="Complemento" className="w-[425px]">
              <Input />
            </Form.Item>
          </Row>
          <Form.Item<CreateAddressCompanyType>
            name="neighborhood"
            label="Bairro"
            rules={[{ required: true, message: "Por favor, insira bairro." }]}
          >
            <Input />
          </Form.Item>

          <Row className="flex justify-between">
            <Form.Item<CreateAddressCompanyType>
              className="w-[370px]"
              name="city"
              label="Cidade"
              rules={[{ required: true, message: "Por favor, insira cidade." }]}
            >
              <Input />
            </Form.Item>
            <Form.Item<CreateAddressCompanyType>
              name="state"
              label="Estado"
              rules={[{ required: true, message: "Por favor, insira estado." }]}
            >
              <Select
                showSearch
                allowClear
                listItemHeight={10}
                listHeight={150}
                style={{ width: 170 }}
                options={listUfs.map(({ nome, sigla }) => ({
                  label: nome,
                  value: sigla,
                }))}
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(input.toLowerCase()) ||
                  (option?.value.toLowerCase() ?? "").includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Row>
          <FormItemInputMoney form={form} required name="default_transport_price" label="Preço transporte padrão" />
          <FormItemInputMoney
            form={form}
            required
            name="default_transport_emergency_price"
            label="Preço transporte emergencial"
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="system_material">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
