import { useContext, useEffect, useState } from "react";
import { useSpecialsStore } from "../store";
import { Button, Form, Input, Segmented, Select, Table, Tag, Tooltip } from "antd";
import Selector from "@components/shared/Selector";
import { Icon } from "@iconify/react";
import StatusSelect from "@components/shared/StatusSelect";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Key } from "antd/es/table/interface";
import { ItemDocumentType } from "../types";

const { TextArea } = Input;

function GenerateWorkOrder() {
  const {
    listServicesAvailableForCustomer,
    listServicesAvailable,
    loadingListServices,
    simpleListUsersCustomer,
    users,
    loadingUsers,
    simpleListDepartmentCustomer,
    loadingDepartments,
    departments,
    simpleListAddressesCustomer,
    addresses,
    loadingAddresses,
    payload,
  } = useSpecialsStore();
  useEffect(() => {
    (async () => await listServicesAvailableForCustomer())();
  }, []);
  const { onClose } = useContext(D3DrawerContext);
  const [form] = Form.useForm();
  const columns = [
    {
      title: "Documento",
      dataIndex: "fs_document_physical_tag_id",
      key: "fs_document_physical_tag_id",
    },
    {
      title: "Repositório",
      dataIndex: "repository_physical_tag_id",
      key: "repository_physical_tag_id",
    },
    {
      title: "Descrição para Localização",
      dataIndex: "description",
      key: "description",
      render: (value: any, record: any) => (
        <Form.Item noStyle name={[record.fs_document_physical_tag_id, "description"]}>
          <Input />
        </Form.Item>
      ),
    },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);

  const toggleExpandAll = () => {
    if (expandedRowKeys.length !== payload.fs_documents.length) {
      setExpandedRowKeys(payload.fs_documents.map((item) => item.fs_document_physical_tag_id));
    } else {
      setExpandedRowKeys([]);
    }
  };

  const toggleExpand = (key: Key, expand: boolean) => {
    if (expand) {
      setExpandedRowKeys(expandedRowKeys.filter((item) => item !== key));
    } else {
      setExpandedRowKeys([key, ...expandedRowKeys]);
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={async (changedValues: any, values: any) => {
          const key = Object.keys(changedValues)[0];
          const value = changedValues[key];
          if (isNaN(Number(key))) {
            useSpecialsStore.setState((state) => ({
              payload: {
                ...state.payload,
                [key]: value,
              },
            }));
          } else {
            useSpecialsStore.setState((state) => {
              const newObj: ItemDocumentType[] = state.payload.fs_documents.map((doc) => {
                if (doc.fs_document_physical_tag_id === key) {
                  return {
                    ...doc,
                    description: value.description,
                  };
                }
                return doc;
              });
              return {
                payload: {
                  ...state.payload,
                  fs_documents: newObj,
                },
              };
            });
          }
          if (changedValues.service) {
            await simpleListUsersCustomer();
            await simpleListDepartmentCustomer();
            await simpleListAddressesCustomer();
            useSpecialsStore.setState({
              serviceSelected: listServicesAvailable.find((srv) => srv.cuid === changedValues.service),
            });
          }
        }}
      >
        <Form.Item
          name="service_cuid"
          label="Serviço"
          rules={[{ required: true, message: "Por favor, selecione um serviço" }]}
        >
          <Selector
            fieldMap={{
              value: "cuid",
              label: "description",
              dataLabel: ["description"],
              dataFilter: ["description"],
            }}
            loading={loadingListServices}
            options={listServicesAvailable}
          />
        </Form.Item>

        <Form.Item
          label="Responsável"
          name="requester_user_cuid"
          rules={[{ required: true, message: "Por favor, selecione um responsável" }]}
        >
          <Selector
            fieldMap={{
              status: "active",
              value: "cuid",
              label: "username",
              description: "name",
              dataLabel: ["username"],
              dataFilter: ["username", "name"],
              disabled: true,
            }}
            loading={loadingUsers}
            options={users}
          />
        </Form.Item>

        <Form.Item
          label="Responsável"
          name="requester_person_name"
          rules={[{ required: true, message: "Por favor, insira o responsável" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="requester_person_email"
          rules={[{ required: true, message: "Por favor, insira o email." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Departamento Faturamento" name="department_cuid">
          <Selector
            fieldMap={{
              status: "active",
              value: "cuid",
              label: "description",
              description: "abbreviation",
              dataLabel: ["description", "abbreviation"],
              dataFilter: ["description", "abbreviation"],
              disabled: true,
            }}
            loading={loadingDepartments}
            options={departments}
          />
        </Form.Item>

        <Form.Item>
          <Table
            rowKey="fs_document_physical_tag_id"
            columns={columns}
            dataSource={payload.fs_documents}
            bordered
            size="small"
            pagination={false}
            expandable={{
              expandedRowRender: (record) => (
                <>teste</>
                /*  <Descriptions layout="horizontal" className="max-w-max" size="small" column={1} bordered>
                  <Descriptions.Item label="Descrição do documento" key="title">
                    {record.fs_document_name}
                  </Descriptions.Item>
                  {record.indexes_values.map((index) => (
                    <Descriptions.Item label={index.label} key={index.order}>
                      {convertIndexerValue(index)}
                    </Descriptions.Item>
                  ))}
                </Descriptions> */
              ),
              expandedRowKeys,
              columnTitle: (
                <div className="flex justify-center">
                  <span
                    className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
                      expandedRowKeys.length === payload.fs_documents.length ? "expanded" : "collapsed"
                    }`}
                    onClick={(e) => {
                      toggleExpandAll();
                    }}
                  />
                </div>
              ),
              expandIcon: ({ expanded, onExpand, record }) => (
                <span
                  className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
                    expanded ? "expanded" : "collapsed"
                  } `}
                  onClick={(e) => {
                    toggleExpand(record.fs_document_physical_tag_id, expanded);
                    onExpand(record, e);
                  }}
                />
              ),
            }}
          />
        </Form.Item>

        <Form.Item
          label="Endereço"
          name="address_cuid"
          rules={[{ required: true, message: "Por favor, selecione um endereço." }]}
        >
          <Select
            loading={loadingAddresses}
            listItemHeight={10}
            listHeight={250}
            allowClear
            removeIcon={
              <Icon
                icon="iconamoon:close-duotone"
                className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
              />
            }
            menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
            optionLabelProp="dataLabel"
            showSearch
            maxTagCount="responsive"
            size="middle"
            className="w-full truncate select-none"
            optionFilterProp="children"
            filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
          >
            {addresses.map(
              ({
                city,
                complement,
                cuid,
                description,
                state,
                street_address,
                street_number,
                zip_code,
                active,
                is_default,
              }) => (
                <Select.Option
                  key={cuid}
                  value={cuid}
                  dataFilter={`${description} ${addresses}`}
                  dataLabel={`${description}`}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{description}</span>
                      <span className="text-[10px] truncate">{`${street_address}, nº ${street_number}${
                        complement ? ", " + complement : ""
                      }, ${city} - ${state}, Cep ${zip_code}`}</span>
                    </div>
                    <div className="flex items-center justify-between gap-2">
                      {is_default && (
                        <Tooltip title="Endereço Padrão" placement="right">
                          <Tag
                            className="flex items-center justify-between gap-1"
                            icon={<Icon icon="bi:geo-alt-fill" />}
                            color="success"
                          >
                            Padrão
                          </Tag>
                        </Tooltip>
                      )}
                      <StatusSelect status={active} />
                    </div>
                  </div>
                </Select.Option>
              )
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Email" name="email" rules={[{ required: true, message: "Por favor, insira o email." }]}>
          <Input />
        </Form.Item>

        <Form.Item name="emerge" label="Transporte Emergencial?">
          <Segmented
            options={[
              { label: "Sim", value: true },
              { label: "Não", value: false },
            ]}
          />
        </Form.Item>

        <Form.Item label="Observação" name="observation">
          <TextArea rows={4} />
        </Form.Item>

        <div className="grid grid-cols-2 gap-4">
          <Button htmlType="submit" type="primary">
            Finalizar Ordem de Serviço
          </Button>
          <Button type="dashed" onClick={() => onClose()} danger>
            Fechar Tela e Continuar Adicionando Documento à Ordem
          </Button>
        </div>
      </Form>
    </>
  );
}

export default GenerateWorkOrder;
