import D3RepositorySituation from "@utils/D3RepositorySituation";
import { Button, Table, Tooltip } from "antd";
import { useSpecialsStore } from "../store";
import { Icon } from "@iconify/react";

const columns = [
  {
    showSorterTooltip: false,
    key: "fs_document_physical_tag_id",
    title: "Documento",
    dataIndex: "fs_document_physical_tag_id",
  },
  {
    showSorterTooltip: false,
    key: "repository_physical_tag_id",
    title: "Repositório",
    dataIndex: "repository_physical_tag_id",
  },
  {
    showSorterTooltip: false,
    key: "billable_department",
    title: "Departamento",
    dataIndex: ["billable_department", "description"],
  },
  {
    showSorterTooltip: false,
    key: "fs_document_status",
    title: "Situação",
    dataIndex: "fs_document_status",
    render: (value: string) => D3RepositorySituation(value),
  },
  {
    showSorterTooltip: false,
    key: "action",
    title: "Ações",
    dataIndex: "action",
    align: "center" as const,
    render: (_: any, record: any) => (
      <div className="flex items-center justify-center">
        <Tooltip title="Ir para documento estruturado" destroyTooltipOnHide mouseLeaveDelay={0} zIndex={11}>
          <Button
            className="peer"
            type="text"
            shape="default"
            danger
            icon={
              <Icon
                icon="material-symbols:delete"
                height={18}
                width={18}
                className="text-red-400 transition-all duration-100 ease-in hover:text-red-500"
              />
            }
            size={"middle"}
            onClick={() =>
              useSpecialsStore.setState((state) => ({
                selectedRows: state.selectedRows.filter((doc) => doc.fs_document_id !== record.fs_document_id),
                documentsSelected: state.documentsSelected.filter(
                  (doc) => doc.fs_document_id !== record.fs_document_id
                ),
              }))
            }
          />
        </Tooltip>
      </div>
    ),
  },
];
function ViewSelected() {
  const { documentsSelected } = useSpecialsStore();
  return (
    <div className="p-4">
      <Table pagination={false} size="small" bordered columns={columns} dataSource={documentsSelected} />
    </div>
  );
}

export default ViewSelected;
