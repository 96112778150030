import DashboardView from "@views/DashboardView";
import ChangePasswordView from "@views/auth/change-password";
import ForgotPasswordView from "@views/auth/forgot-password";
import AuthView from "@views/auth";
import LoginView from "@views/auth/login";
import EventsView from "@views/company/billing/events";
import CustomerGroupsView from "@views/company/customer-groups";
import CustomersView from "@views/company/customers";
import CompanyPermissionsGroupsView from "@views/company/permissions-groups";
import PhysicalTagsView from "@views/company/physical-tags";
import CellsView from "@views/company/storages/shelves/cells";
import ShelvesView from "@views/company/storages/shelves/this";
import StoragesView from "@views/company/storages/this";
import CompanyView from "@views/company/this";
import CompanyUsersView from "@views/company/users";
import CGRCustomersView from "@views/customer-group/customers";
import CustomerGroupView from "@views/customer-group/this";
import CustomerGroupUsersView from "@views/customer-group/users";
import DepartmentsView from "@views/customer/departments";
import RegisterDigitalDocument from "@views/customer/document-registration/digital";
import RegisterPhysicalDocument from "@views/customer/document-registration/physical";
import DocumentTypesView from "@views/customer/document-types";
import MarkersView from "@views/customer/markers";
import CustomerPermissionsGroupsView from "@views/customer/permissions-groups";
import CustomerPhysicalTagsView from "@views/customer/physical-tags";
import RepositoriesView from "@views/customer/production/repositories";
import SpecialsView from "@views/customer/spaces/special";
import CustomerCellsView from "@views/customer/storages/shelves/cells";
import CustomerShelvesView from "@views/customer/storages/shelves/this";
import CustomerStoragesView from "@views/customer/storages/this";
import CustomerView from "@views/customer/this";
import CustomerUsersView from "@views/customer/users";
import DeploymentOrdersView from "@views/orders/deployment";
import { CreateDeploymentOrderForm } from "@views/orders/deployment/components/CreateDeploymentOrderForm";
import MaterialOrdersView from "@views/orders/material";
import ProtocolOrdersView from "@views/orders/protocol";
import SpecialOrdersView from "@views/orders/special";
import ExtensionsView from "@views/system/extensions";
import MaterialsView from "@views/system/materials";
import OperationsView from "@views/system/operations";
import RepositoryTypesView from "@views/system/repository-types";
import ServicesView from "@views/system/services";
import AdministrativeTools from "@views/system/tools";
import ManagePasswordView from "@views/system/user/manage";
import UserView from "@views/system/user/this";
import { Outlet, createHashRouter } from "react-router-dom";
import App from "./App";
import D3ProtectPage from "./components/shared/D3ProtectPage";
import TrashView from "@views/customer/spaces/trash";
import Navigate from "@views/customer/spaces/work";
import NavigateBody from "@views/customer/spaces/work/components/navigate/NavigateBody";
import RelocationView from "@views/orders/relocations";
import { CreateRelocationForm } from "@views/orders/relocations/components/CreateRelocationForm";
import ContactTypesView from "@views/system/contact-types";
import InvoicesView from "@views/company/billing/invoices";
import RegisterDocumentBatch from "@views/customer/document-registration/batch";

const router = createHashRouter([
  {
    path: "/",
    element: <AuthView />,
    children: [
      {
        path: "",
        element: <LoginView />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthView />,
    children: [
      {
        path: "login",
        element: <LoginView />,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordView />,
      },
      {
        path: "change-password",
        element: <ChangePasswordView />,
      },
    ],
  },
  {
    path: "/app",
    element: <App />,
    children: [
      {
        path: "new",
        element: <></>,
      },
      {
        path: "orders/",
        element: <Outlet />,
        children: [
          {
            path: "material",
            element: <MaterialOrdersView />,
          },
          {
            path: "deployment",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <DeploymentOrdersView />,
              },
              {
                path: "create",
                element: (
                  <D3ProtectPage
                    targetPage={<CreateDeploymentOrderForm />}
                    isRequiredCustomerGroup={false}
                    isRequiredCustomer={true}
                    resource="order_deployment"
                    action="create"
                  />
                ),
              },
            ],
          },
          {
            path: "relocation",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <RelocationView />,
              },
              {
                path: "create",
                element: (
                  <D3ProtectPage
                    targetPage={<CreateRelocationForm />}
                    isRequiredCustomerGroup={false}
                    isRequiredCustomer={false}
                    resource="order_relocation"
                    action="create"
                  />
                ),
              },
            ],
          },
          {
            path: "protocol",
            element: <ProtocolOrdersView />,
          },
          {
            path: "special",
            element: <SpecialOrdersView />,
          },
        ],
      },

      {
        path: "",
        element: <DashboardView />,
      },
      {
        path: "manage-password",
        element: <ManagePasswordView />,
      },
      {
        path: "system",
        element: <Outlet />,
        children: [
          {
            path: "materials",
            element: (
              <D3ProtectPage
                targetPage={<MaterialsView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="system_material"
                action="view"
              />
            ),
          },
          {
            path: "operations",
            element: (
              <D3ProtectPage
                targetPage={<OperationsView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="system_operation"
                action="view"
              />
            ),
          },
          {
            path: "repository-types",
            element: (
              <D3ProtectPage
                targetPage={<RepositoryTypesView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="system_repository_type"
                action="view"
              />
            ),
          },
          {
            path: "services",
            element: (
              <D3ProtectPage
                targetPage={<ServicesView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="system_service"
                action="view"
              />
            ),
          },
          {
            path: "extensions",
            element: (
              <D3ProtectPage
                targetPage={<ExtensionsView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="all"
                action="manage"
              />
            ),
          },
          {
            path: "contact-types",
            element: (
              <D3ProtectPage
                targetPage={<ContactTypesView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="all"
                action="manage"
              />
            ),
          },
          {
            path: "user",
            element: <UserView />,
          },
          {
            path: "tools",
            element: (
              <D3ProtectPage
                targetPage={<AdministrativeTools />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="admin_tool"
                action="view"
              />
            ),
          },
        ],
      },
      {
        path: "company",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: (
              <D3ProtectPage
                targetPage={<CompanyView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="company"
                action="details"
              />
            ),
          },
          {
            path: "physical-tags",
            element: (
              <D3ProtectPage
                targetPage={<PhysicalTagsView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="company_physical_tag"
                action="view"
              />
            ),
          },
          {
            path: "customers",
            element: (
              <D3ProtectPage
                targetPage={<CustomersView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="customer"
                action="view"
              />
            ),
          },
          {
            path: "storages",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: (
                  <D3ProtectPage
                    targetPage={<StoragesView />}
                    isRequiredCustomerGroup={false}
                    isRequiredCustomer={false}
                    resource="company_storage"
                    action="view"
                  />
                ),
              },
              {
                path: ":storageCuid/shelves",
                element: <Outlet />,
                children: [
                  {
                    path: "",
                    element: (
                      <D3ProtectPage
                        targetPage={<ShelvesView />}
                        isRequiredCustomerGroup={false}
                        isRequiredCustomer={false}
                        resource="company_storage"
                        action="view"
                      />
                    ),
                  },
                  {
                    path: ":shelfCuid",
                    element: (
                      <D3ProtectPage
                        targetPage={<CellsView />}
                        isRequiredCustomerGroup={false}
                        isRequiredCustomer={false}
                        resource="company_storage"
                        action="view"
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "users",
            element: (
              <D3ProtectPage
                targetPage={<CompanyUsersView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="company_user"
                action="view"
              />
            ),
          },
          {
            path: "permissions-groups",
            element: (
              <D3ProtectPage
                targetPage={<CompanyPermissionsGroupsView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="company_permission_group"
                action="view"
              />
            ),
          },
          {
            path: "customer-groups",
            element: (
              <D3ProtectPage
                targetPage={<CustomerGroupsView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="customer_group"
                action="view"
              />
            ),
          },
        ],
      },
      {
        path: "customer-group",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: (
              <D3ProtectPage
                targetPage={<CustomerGroupView />}
                isRequiredCustomerGroup={true}
                isRequiredCustomer={false}
                resource="customer_group"
                action="details"
              />
            ),
          },
          {
            path: "users",
            element: (
              <D3ProtectPage
                targetPage={<CustomerGroupUsersView />}
                isRequiredCustomerGroup={true}
                isRequiredCustomer={false}
                resource="customer_group_user"
                action="view"
              />
            ),
          },
          {
            path: "customers",
            element: (
              <D3ProtectPage
                targetPage={<CGRCustomersView />}
                isRequiredCustomerGroup={true}
                isRequiredCustomer={false}
                resource="customer"
                action="view"
              />
            ),
          },
        ],
      },
      {
        path: "billing",
        element: <Outlet />,
        children: [
          {
            path: "events",
            element: (
              <D3ProtectPage
                targetPage={<EventsView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="billing"
                action="view"
              />
            ),
          },
          {
            path: "invoices",
            element: (
              <D3ProtectPage
                targetPage={<InvoicesView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={false}
                resource="billing"
                action="view"
              />
            ),
          },
        ],
      },
      {
        path: "customer",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: (
              <D3ProtectPage
                targetPage={<CustomerView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={true}
                resource="customer"
                action="details"
              />
            ),
          },
          {
            path: "spaces",
            element: <Outlet />,
            children: [
              {
                path: "trash",
                element: (
                  <D3ProtectPage
                    targetPage={<TrashView />}
                    isRequiredCustomerGroup={false}
                    isRequiredCustomer={true}
                    resource="customer_fs"
                    action="view"
                  />
                ),
              },
              {
                path: "work/special",
                element: (
                  <D3ProtectPage
                    targetPage={<SpecialsView />}
                    isRequiredCustomerGroup={false}
                    isRequiredCustomer={true}
                    resource="customer_fs"
                    action="view"
                  />
                ),
              },
              {
                path: "work",
                element: (
                  <D3ProtectPage
                    targetPage={<Navigate />}
                    isRequiredCustomerGroup={false}
                    isRequiredCustomer={true}
                    resource="customer_fs"
                    action="view"
                  />
                ),
                children: [
                  {
                    path: ":cuid",
                    element: (
                      <D3ProtectPage
                        targetPage={<NavigateBody />}
                        isRequiredCustomerGroup={false}
                        isRequiredCustomer={true}
                        resource="customer_fs"
                        action="view"
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "departments",
            element: (
              <D3ProtectPage
                targetPage={<DepartmentsView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={true}
                resource="customer_department"
                action="view"
              />
            ),
          },
          {
            path: "document-types",
            element: (
              <D3ProtectPage
                targetPage={<DocumentTypesView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={true}
                resource="customer_document_type"
                action="view"
              />
            ),
          },
          {
            path: "register-physical-document",
            element: (
              <D3ProtectPage
                targetPage={<RegisterPhysicalDocument />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={true}
                resource="customer_fs_document"
                action="view"
              />
            ),
          },
          {
            path: "register-physical-document-batch",
            element: (
              <D3ProtectPage
                targetPage={<RegisterDocumentBatch />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={true}
                resource="customer_fs_document"
                action="view"
              />
            ),
          },
          {
            path: "register-digital-document",
            element: (
              <D3ProtectPage
                targetPage={<RegisterDigitalDocument />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={true}
                resource="customer_fs_document"
                action="view"
              />
            ),
          },
          {
            path: "users",
            element: (
              <D3ProtectPage
                targetPage={<CustomerUsersView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={true}
                resource="customer_user"
                action="view"
              />
            ),
          },
          {
            path: "permission-groups",
            element: (
              <D3ProtectPage
                targetPage={<CustomerPermissionsGroupsView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={true}
                resource="customer_permission_group"
                action="view"
              />
            ),
          },
          {
            path: "storages",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: (
                  <D3ProtectPage
                    targetPage={<CustomerStoragesView />}
                    isRequiredCustomerGroup={false}
                    isRequiredCustomer={true}
                    resource="customer_storage"
                    action="view"
                  />
                ),
              },
              {
                path: ":storageCuid/shelves",
                element: <Outlet />,
                children: [
                  {
                    path: "",
                    element: (
                      <D3ProtectPage
                        targetPage={<CustomerShelvesView />}
                        isRequiredCustomerGroup={false}
                        isRequiredCustomer={true}
                        resource="customer_storage"
                        action="view"
                      />
                    ),
                  },
                  {
                    path: ":shelfCuid",
                    element: (
                      <D3ProtectPage
                        targetPage={<CustomerCellsView />}
                        isRequiredCustomerGroup={false}
                        isRequiredCustomer={true}
                        resource="customer_storage"
                        action="view"
                      />
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "production",
            element: <Outlet />,
            children: [
              {
                path: "repositories",
                element: (
                  <D3ProtectPage
                    targetPage={<RepositoriesView />}
                    isRequiredCustomerGroup={false}
                    isRequiredCustomer={true}
                    resource="customer_repository"
                    action="view"
                  />
                ),
              },
            ],
          },
          {
            path: "markers",
            element: (
              <D3ProtectPage
                targetPage={<MarkersView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={true}
                resource="customer_marker"
                action="view"
              />
            ),
          },
          {
            path: "physical-tags",
            element: (
              <D3ProtectPage
                targetPage={<CustomerPhysicalTagsView />}
                isRequiredCustomerGroup={false}
                isRequiredCustomer={true}
                resource="customer_physical_tag"
                action="view"
              />
            ),
          },
        ],
      },
    ],
  },
]);

export default router;
