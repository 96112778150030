import { create } from "zustand";
import {
  CreateCustomerUserType,
  CustomerUserListItemType,
  ListCustomerUserType,
  LockOrUnlockPayloadType,
  SearchCustomerUserType,
  SelectDepartmentItemType,
  SelectPermissionsGroupItemType,
  SimpleListDepartmentType,
  SimpleListPermissionsGroupType,
  UpdateCustomerUserType,
  ViewCustomerUserType,
} from "./types";

import showMessage from "@utils/showMessage";

import D3QSGenerator, { FilterDefinition } from "@utils/D3QSGenerator";
import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import IsValidMultiSelectedRow from "@utils/table/IsValidMultiSelectedRow";
import { SetSort, SortType } from "@utils/table/SetSort";

type CustomerUserStoreState = {
  apiResult: ListCustomerUserType;
  selectedRows: { cuids: string[]; active: boolean };
  filter: SearchCustomerUserType;
  pagination: { page: number; pageSize: number };
  sort: { sort_by: string; sort_direction: string };
  loading: boolean;
  setFilter: (filter: SearchCustomerUserType) => void;
  setPagination: (page: number, pageSize: number) => void;
  setSort: (newSort: SortType) => void;
  loadCustomerUsers: () => Promise<Success<ListCustomerUserType> | BadRequest | StandardError>;
  getCustomerUser: (cuid: string) => Promise<Success<ViewCustomerUserType> | StandardError>;
  getPermissionsGroupForSelect: () => Promise<SelectPermissionsGroupItemType[]>;
  getDepartmentsForSelect: () => Promise<SelectDepartmentItemType[]>;
  createCustomerUser: (
    customerUser: CreateCustomerUserType
  ) => Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError>;
  updateCustomerUser: (
    cuid: string,
    customerUser: UpdateCustomerUserType
  ) => Promise<Success<{ status: string }> | BadRequest | StandardError>;
  lockCustomerUser: (cuids: string[]) => Promise<void>;
  unLockCustomerUser: (cuids: string[]) => Promise<void>;
  onMultiSelectedRowChange: (selectedRows: CustomerUserListItemType[]) => void;
  generatePassword: (userCuid: string) => Promise<void>;
  resetSelectedRows: () => void;
  resetFilter: () => void;
  resetState: () => void;
};

const defaultSort = {
  sort_by: "u_name",
  sort_direction: "asc",
};

const operationsFilter: FilterDefinition = {
  u_name: { op: "lk", type: "string" },
  u_email: { op: "lk", type: "string" },
  u_username: { op: "lk", type: "string" },
  u_active: { op: "eq", type: "active" },
};

const defaultState = {
  apiResult: {
    total: 0,
    data: [],
  },
  selectedRows: {
    cuids: [],
    active: false,
  },
  filter: {
    u_name: "",
    u_email: "",
    u_username: "",
    u_active: "on",
  } as SearchCustomerUserType,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: defaultSort,
  loading: false,
};

export const useCustomerUsersStore = create<CustomerUserStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchCustomerUserType) => {
    set({
      filter,
      selectedRows: { cuids: [], active: false },
      pagination: { ...get().pagination, page: 1 },
    });
    get().loadCustomerUsers();
  },
  onMultiSelectedRowChange: (selectedRows: CustomerUserListItemType[]): void =>
    IsValidMultiSelectedRow(selectedRows, get, set),
  resetSelectedRows: () => {
    set({ selectedRows: { cuids: [], active: false } });
  },
  resetFilter: () => {
    set({
      filter: { u_name: "", u_email: "", u_username: "", u_active: "on" },
    });
    get().loadCustomerUsers();
  },
  setPagination: (page: number, pageSize: number) => {
    if (pageSize !== get().pagination.pageSize) {
      page = 1;
    }
    set({ pagination: { page, pageSize } });
    get().resetSelectedRows();
    get().loadCustomerUsers();
  },
  setSort: (newSort: SortType) =>
    SetSort(
      newSort,
      get().sort,
      defaultSort,
      new Map<string, string>([
        ["name", "u_name"],
        ["email", "u_email"],
        ["username", "u_username"],
        ["active", "u_active"],
      ]),
      set,
      get().loadCustomerUsers
    ),
  loadCustomerUsers: async (): Promise<Success<ListCustomerUserType> | BadRequest | StandardError> => {
    set({ loading: true });

    let qs = D3QSGenerator(get().filter, operationsFilter, get().pagination, get().sort);

    return await new ClientHttp().get<Success<ListCustomerUserType>, BadRequest | StandardError>(
      `/api/v1/customer/users?${qs}`,
      (result: Success<ListCustomerUserType>) => {
        let newPage = get().pagination.page;
        const { body } = result;
        if (body.total > 0 && body.data.length === 0) {
          newPage = newPage - 1;
          set({
            apiResult: body,
            loading: false,
            pagination: { ...get().pagination, page: newPage },
          });
          get().loadCustomerUsers();
        } else {
          set({ apiResult: body, loading: false });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  getCustomerUser: async (cuid: string): Promise<Success<ViewCustomerUserType> | StandardError> => {
    return await new ClientHttp().get<Success<ViewCustomerUserType>, StandardError>(`/api/v1/customer/users/${cuid}`);
  },
  getPermissionsGroupForSelect: async (): Promise<SelectPermissionsGroupItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListPermissionsGroupType>,
      StandardError,
      SelectPermissionsGroupItemType[]
    >(
      "/api/v1/customer/permissions-groups/simple-list",
      (result: Success<SimpleListPermissionsGroupType>): SelectPermissionsGroupItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.name,
            active: item.active,
          } as SelectPermissionsGroupItemType;
        });
      },
      (error: StandardError): SelectPermissionsGroupItemType[] => {
        showMessage(error, "Erro ao carregar lista grupos de permissões.");
        return [] as SelectPermissionsGroupItemType[];
      }
    );
  },

  getDepartmentsForSelect: async (): Promise<SelectDepartmentItemType[]> => {
    return await new ClientHttp().getItensForSelect<
      Success<SimpleListDepartmentType>,
      StandardError,
      SelectDepartmentItemType[]
    >(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentType>): SelectDepartmentItemType[] => {
        return result.body.data.map((item) => {
          return {
            value: item.cuid,
            label: item.description,
            active: item.active,
            abbreviation: item.abbreviation,
          } as SelectDepartmentItemType;
        });
      },
      (error: StandardError): SelectDepartmentItemType[] => {
        showMessage(error, "Erro ao carregar lista departamentos.");
        return [] as SelectDepartmentItemType[];
      }
    );
  },

  createCustomerUser: async (
    customerUser: CreateCustomerUserType
  ): Promise<Success<{ data: { cuid: string } }> | BadRequest | StandardError> => {
    return await new ClientHttp().post<
      CreateCustomerUserType,
      Success<{ data: { cuid: string } }>,
      BadRequest | StandardError
    >("/api/v1/customer/users", customerUser, (result: Success<{ data: { cuid: string } }>) => {
      get().loadCustomerUsers();
    });
  },
  updateCustomerUser: async (
    cuid: string,
    customerUser: UpdateCustomerUserType
  ): Promise<Success<{ status: string }> | BadRequest | StandardError> => {
    return await new ClientHttp().put<UpdateCustomerUserType, Success<{ status: string }>, BadRequest | StandardError>(
      `/api/v1/customer/users/${cuid}`,
      customerUser,
      (result: Success<{ status: string }>) => {
        get().loadCustomerUsers();
      }
    );
  },
  lockCustomerUser: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/customer/users/lock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadCustomerUsers();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens bloqueados com sucesso.");
        } else {
          showMessage(result, "Item bloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
  unLockCustomerUser: async (cuids: string[]): Promise<void> => {
    await new ClientHttp().patch<
      LockOrUnlockPayloadType,
      Success<{ rows_affected: number; status: string }>,
      BadRequest | StandardError
    >(
      `/api/v1/customer/users/unlock`,
      { cuids: cuids },
      (result: Success<{ rows_affected: number; status: string }>) => {
        get().resetSelectedRows();
        get().loadCustomerUsers();
        if (result.body.rows_affected > 1) {
          showMessage(result, "Itens desbloqueados com sucesso.");
        } else {
          showMessage(result, "Item desbloqueado com sucesso.");
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  generatePassword: async (userCuid: string): Promise<void> => {
    await new ClientHttp().patch<null, Success<void>, BadRequest | StandardError>(
      `/api/v1/customer/users/${userCuid}/generate-password`,
      null,
      (result: Success<void>) => {
        showMessage(result, "Nova senha enviada com sucesso.");
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },
}));
