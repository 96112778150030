import { D3DrawerContext } from "@provider/D3DrawerContext";
import { Button, DatePicker, Divider, Form, Radio, Select, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import RenderIndexers from "./indexers/RenderIndexer";
import { UpdateIndexersType, ItemDocumentTypeType, IndexItemType } from "../../types";
import useStructuredDocumentStore from "../../store";
import dayjs from "dayjs";
import StatusSelect from "@components/shared/StatusSelect";

type EditIndexersFormProps = {
  documentCuid: string;
  show: boolean;
};

function NewIndexersForm({ documentCuid, show }: EditIndexersFormProps) {
  const { onClose } = useContext(D3DrawerContext);
  const { updateIndexers, simpleListDocumentTypesCustomer, listIndexersDocumentTypesByCuid, view } =
    useStructuredDocumentStore();
  const [form] = Form.useForm<UpdateIndexersType>();
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [newIndexes, setNewIndexes] = useState<IndexItemType[]>([]);
  const [loadingIndexers, setLoadingIndexers] = useState<boolean>(false);
  const [documentTypes, setDocumentTypes] = useState<ItemDocumentTypeType[]>([]);
  const [documentTypeSelected, setDocumentTypeSelected] = useState<ItemDocumentTypeType>();

  useEffect(() => {
    (async () => {
      if (show) {
        const data = await simpleListDocumentTypesCustomer();
        setDocumentTypes(data);
        setLoading(false);
      }
    })();
  }, [simpleListDocumentTypesCustomer, show]);

  return show ? (
    <Form
      className="flex flex-col h-full overflow-hidden "
      form={form}
      layout="vertical"
      onFinish={async (payload: UpdateIndexersType) => {
        setLoadingSave(true);
        payload.expected_purge_date = payload.is_permanent_storage
          ? ""
          : dayjs(payload.expected_purge_date).format("YYYY-MM-DD");
        const result = await updateIndexers(documentCuid, payload);
        if (result) {
          onClose();
        }
        setLoadingSave(false);
      }}
      onValuesChange={async (changedValues, allValues) => {
        if (changedValues.document_type_cuid) {
          const documentType = documentTypes.find((item) => item.cuid === changedValues.document_type_cuid);
          setDocumentTypeSelected(documentType);
          if (documentType) {
            const purgeDate =
              documentType.temporality_year > 0
                ? dayjs(view.original_date).add(documentType.temporality_year, "year")
                : "";
            form.setFieldValue("expected_purge_date", purgeDate);
            form.setFieldValue("is_permanent_storage", documentType.temporality_permanent_guard);
          }
          setLoadingIndexers(true);
          const data = await listIndexersDocumentTypesByCuid(changedValues.document_type_cuid);
          setNewIndexes(data);
          form.setFieldsValue({
            indexes_values: data.map((item) => {
              return {
                document_type_index_id: item.id,
                end_value: "",
                start_value: "",
                value: "",
              };
            }),
          });
          setLoadingIndexers(false);
        }
        if (changedValues.original_date || changedValues.is_permanent_storage === false) {
          if (documentTypeSelected) {
            const purgeDate =
              documentTypeSelected.temporality_year > 0
                ? dayjs(changedValues.original_date).add(documentTypeSelected.temporality_year, "year")
                : "";
            form.setFieldValue("expected_purge_date", purgeDate);
          }
        }
        if (changedValues.original_date === "" || changedValues.is_permanent_storage) {
          form.setFieldValue("expected_purge_date", "");
        }
      }}
    >
      <div className="flex flex-col h-full px-2 overflow-hidden">
        <div className="h-full overflow-y-auto grow custom-scroll">
          <Form.Item<UpdateIndexersType>
            name="document_type_cuid"
            className="px-2"
            label="Tipo de Documento"
            rules={[
              {
                required: true,
                message: "Por favor, selecione o tipo de documento.",
              },
            ]}
          >
            <Select
              disabled={documentTypes.length === 0}
              loading={loading}
              listItemHeight={10}
              allowClear
              removeIcon={
                <Icon
                  icon="iconamoon:close-duotone"
                  className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                />
              }
              menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
              optionLabelProp="dataLabel"
              showSearch
              maxTagCount="responsive"
              size="middle"
              className="w-full truncate select-none"
              optionFilterProp="children"
              filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
            >
              {documentTypes.map(({ active, cuid, description, temporality_permanent_guard, temporality_year }) => (
                <Select.Option
                  disabled={!active}
                  key={cuid}
                  value={cuid}
                  dataFilter={`${description}`}
                  dataLabel={`${description} - ${
                    temporality_permanent_guard === true
                      ? "Tempo indeterminado"
                      : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
                  }`}
                >
                  <div className="flex items-center justify-between ">
                    <div className="flex flex-col truncate">
                      <span className="truncate">{`${description} - ${
                        temporality_permanent_guard === true
                          ? "Tempo indeterminado"
                          : `${temporality_year} ${temporality_year > 1 ? "Anos" : "Ano"}`
                      }`}</span>
                    </div>
                    <StatusSelect status={active} />
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {documentTypeSelected && (
            <div className="flex items-center justify-between mt-3 mb-6 ">
              <Form.Item<UpdateIndexersType>
                className="p-0 m-0 w-44"
                name="is_permanent_storage"
                label="Guarda permanente?"
              >
                <Radio.Group
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
              </Form.Item>

              <Form.Item<UpdateIndexersType>
                getValueProps={(value) => ({ value: value ? dayjs(value) : dayjs() })}
                normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : null)}
                className="p-0 m-0 w-52"
                label="Data original"
                name="original_date"
              >
                <DatePicker
                  disabled
                  format={{
                    format: "DD/MM/YYYY",
                    type: "mask",
                  }}
                />
              </Form.Item>

              <Form.Item<UpdateIndexersType>
                getValueProps={(value) => ({ value: value ? dayjs(value) : value })}
                normalize={(value) => (value ? dayjs(value).format("YYYY-MM-DD") : null)}
                className="p-0 m-0 w-52"
                label="Previsão de expurgo"
                name="expected_purge_date"
                rules={[
                  {
                    required: documentTypeSelected.is_temporality_mandatory && view.is_permanent_storage === false,
                    message: "Tipo de documento selecionado requer uma data previsão de expurgo.",
                  },
                ]}
              >
                <DatePicker
                  format={{
                    format: "DD/MM/YYYY",
                  }}
                />
              </Form.Item>
            </div>
          )}
          {loadingIndexers ? (
            <div className="flex items-center justify-center h-full">
              <Spin />
            </div>
          ) : (
            <>
              {newIndexes.length > 0 && (
                <Divider>
                  <span className="text-sm font-medium">Indexadores</span>
                </Divider>
              )}
              <RenderIndexers form={form} attribute={["indexes_values"]} indexes={newIndexes} />
            </>
          )}
        </div>

        <div className="flex-none w-full h-14">
          <Form.Item className="p-0 m-0">
            <div className="flex items-center justify-end gap-3 h-14">
              <Button type="default" danger onClick={() => onClose()}>
                Cancelar
              </Button>
              <Button loading={loadingSave} type="primary" htmlType="submit">
                Salvar
              </Button>
            </div>
          </Form.Item>
        </div>
      </div>
    </Form>
  ) : (
    <></>
  );
}

export default NewIndexersForm;
