export const enumSituation: Map<string, string> = new Map<string, string>([
  ["STO", "Armazenado"],// pode ser requisitado
  ["REG", "Cadastrado"],// pode ser requisitado
  ["PUR", "Expurgado"],
  ["MIG", "Em migração"],
  ["REQ", "Requisitado"],
  ["IMC", "Cliente"],// pode ser requisitado
]);

function D3RepositorySituation(key: string): string | undefined {
  return enumSituation.get(key);
}

export default D3RepositorySituation;


