import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Space, Spin, Tabs } from "antd";
import { usePermissionGroupsStore } from "../store";
import { CreatePermissionGroupType, SelectCompanyUserItemType } from "../types";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { StandardError } from "@utils/clientHttp";
import { Icon } from "@iconify/react";
import D3PermCheckTable from "@components/shared/D3PermCheckTable/D3PermCheckTable";
import D3Can from "@components/shared/D3Can";
import Selector from "@components/shared/Selector";

export const CreatePermissionGroupForm = () => {
  const { createPermissionGroup, getCompanyUsersForSelect } = usePermissionGroupsStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreatePermissionGroupType>();
  const [companyUsers, setCompanyUsers] = useState<SelectCompanyUserItemType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await getCompanyUsersForSelect();
      setCompanyUsers(result);
      setLoading(false);
    })();
  }, [getCompanyUsersForSelect]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: CreatePermissionGroupType) => {
            setLoading(true);
            const result = await createPermissionGroup(payload);
            if (result.status === "success") {
              showMessage(result, "Grupo Permissões cadastrado com sucesso.");
              onClose();
            } else {
              showMessage(result as StandardError);
            }
            setLoading(false);
          }}
          autoComplete="off"
        >
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            onTabClick={(tab) => (tab === "1" ? setSize(600) : setSize(1200))}
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<CreatePermissionGroupType>
                      name="name"
                      label="Nome"
                      rules={[{ required: true, message: "Por favor, insira nome." }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<CreatePermissionGroupType> label="Usuários" name="company_user_cuids">
                      <Selector
                        fieldMap={{
                          status: "active",
                          value: "value",
                          label: "label",
                          description: "username",
                          dataLabel: ["label"],
                          dataFilter: ["label", "username"],
                        }}
                        mode="multiple"
                        options={companyUsers}
                      />
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "w",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="icon-park-outline:permissions" className="w-[18px] h-[18px]" />
                    Permissões
                  </span>
                ),
                children: (
                  <Form.Item<CreatePermissionGroupType> name="permissions" label="Permissões Personalizadas">
                    <D3PermCheckTable
                      profile="CPN"
                      onChange={(newValues) => {
                        form.setFieldValue("permissions", newValues);
                      }}
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="company_permission_group">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
