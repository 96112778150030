import { Icon } from "@iconify/react";
import D3Copy from "@utils/D3Copy";
import { D3Date } from "@utils/date";
import { Form, Input, Radio, Spin, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { MutationByType, ViewStructuredDocumentType } from "../../types";

type TabDataType = {
  data: ViewStructuredDocumentType;
};

enum Status {
  STO = "Armazenado",
  REG = "Cadastrado",
  PUR = "Expurgado",
  DEL = "Deletado",
  MIG = "Em migração",
  REQ = "Solicitado",
  PRO = "Em processamento",
  INC = "No cliente",
}

enum UserType {
  SYS = "Usuário sistema",
  CPN = "Usuário empresa",
  CTM = "Usuário cliente",
  CGR = "Usuário grupo de cliente",
}

function TabData({ data }: TabDataType) {
  const [form] = Form.useForm<ViewStructuredDocumentType>();

  const [loading, setLoading] = useState<boolean>(true);

  const status = Status[data?.status as keyof typeof Status];

  const isPhysical = data?.is_physical;

  const visibility_department =
    data && data.visibility_department
      ? `${data.visibility_department.abbreviation} - ${data.visibility_department.description} `
      : "Not Available";

  const visibility_department_status = data && data.visibility_department ? data.visibility_department.active : false;

  const billable_department =
    data && data.billable_department
      ? `${data.billable_department.abbreviation} - ${data.billable_department.description} `
      : "Not Available";
  const billable_department_status = data && data.billable_department ? data.billable_department.active : false;

  const created_by = data?.created_by ?? "";
  const updated_by = data?.updated_by ?? "";
  const deleted_by = data?.deleted_by ?? "";

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        original_date: data.expected_purge_date
          ? dayjs(data.expected_purge_date).format("DD/MM/YYYY")
          : "Não preenchido",
        expected_purge_date: data.expected_purge_date
          ? dayjs(data.expected_purge_date).format("DD/MM/YYYY")
          : "Não preenchido",
        created_at: D3Date.FormatValueApiToPtBR(data.created_at),
        updated_at: D3Date.FormatValueApiToPtBR(data.updated_at),
      });
    }
    setLoading(false);
  }, [form, data]);

  const departmentStatus = (status: boolean) => {
    return (
      <div className="flex items-center justify-center">
        <Tooltip trigger={["click", "hover"]} title={status ? "Departamento desbloqueado" : "Departamento Bloqueado"}>
          <span>
            <Icon
              height={16}
              icon={status ? "basil:unlock-solid" : "basil:lock-solid"}
              className={status ? "text-green-500" : "text-red-500"}
            />
          </span>
        </Tooltip>
      </div>
    );
  };

  const inputUserBy = ({ type, active, name }: MutationByType) => {
    return (
      <Input
        value={`${name} - ${UserType[type as keyof typeof UserType]}`}
        className="w-full"
        addonAfter={
          <div className="flex items-center justify-center">
            <Tooltip trigger={["click", "hover"]} title={active ? "Usuário desbloqueado" : "Usuário Bloqueado"}>
              <span>
                <Icon
                  height={16}
                  icon={active ? "basil:unlock-solid" : "basil:lock-solid"}
                  className={active ? "text-green-500" : "text-red-500"}
                />
              </span>
            </Tooltip>
          </div>
        }
      />
    );
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" className="w-full py-2 select-none" disabled>
        <div className="grid w-full grid-cols-2 gap-5">
          <Form.Item<ViewStructuredDocumentType> name="name" label="Nome">
            <Input className="w-full" />
          </Form.Item>
          <Form.Item<ViewStructuredDocumentType> label="Tipo de documento" name={["document_type", "description"]}>
            <Input />
          </Form.Item>
        </div>
        <div className="grid w-full grid-cols-2 gap-5">
          <Form.Item<ViewStructuredDocumentType>
            hidden={!isPhysical}
            name="repository_physical_tag_id"
            label="Código do repositório"
          >
            <Input
              readOnly
              className="w-full"
              addonAfter={
                <Icon
                  onClick={() =>
                    D3Copy({
                      text: form.getFieldValue("repository_physical_tag_id"),
                      success: "Código copiado!",
                    })
                  }
                  className="text-blue-400 transition-all duration-300 ease-in-out cursor-pointer hover:scale-110 hover:text-blue-500 "
                  icon="fluent:copy-32-filled"
                />
              }
            />
          </Form.Item>
          <Form.Item<ViewStructuredDocumentType>
            hidden={!isPhysical}
            name="document_physical_tag_id"
            label="Código do documento"
          >
            <Input
              className="w-full"
              addonAfter={
                <Icon
                  onClick={() =>
                    D3Copy({
                      text: form.getFieldValue("document_physical_tag_id"),
                      success: "Código copiado!",
                    })
                  }
                  className="text-blue-400 transition-all duration-300 ease-in-out cursor-pointer hover:scale-110 hover:text-blue-500 "
                  icon="fluent:copy-32-filled"
                />
              }
            />
          </Form.Item>
        </div>

        <div className="grid w-full grid-cols-2 gap-5">
          <Form.Item<ViewStructuredDocumentType> name="original_date" label="Data de origem">
            <Input className="w-full" />
          </Form.Item>
          <Form.Item<ViewStructuredDocumentType> name="expected_purge_date" label="Previsão de expurgo">
            <Input className="w-full" />
          </Form.Item>
        </div>
        <div className="grid w-full grid-cols-2 gap-5">
          <Form.Item<ViewStructuredDocumentType> label="Status">
            <Tag color={status === "Deletado" || status === "Expurgado" ? "error" : "processing"}>{status}</Tag>
          </Form.Item>
          <Form.Item<ViewStructuredDocumentType> label="Departamento de faturamento">
            <Input
              value={billable_department}
              addonAfter={billable_department ? departmentStatus(billable_department_status) : null}
            />
          </Form.Item>
        </div>
        <div className="grid w-full grid-cols-3 gap-5">
          <Form.Item<ViewStructuredDocumentType> name="is_physical" label="Físico ?">
            <Radio.Group
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
            />
          </Form.Item>
          <Form.Item<ViewStructuredDocumentType> name="is_permanent_storage" label="Guarda permanente ?">
            <Radio.Group
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
            />
          </Form.Item>
          <Form.Item<ViewStructuredDocumentType> name="global_visibility" label="Visibilidade global ?">
            <Radio.Group
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                {
                  label: "Não",
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className="grid w-full grid-cols-2 gap-5">
          <Form.Item<ViewStructuredDocumentType> label="Criado por">
            {created_by ? inputUserBy(created_by) : ""}
          </Form.Item>
          <Form.Item<ViewStructuredDocumentType> name="created_at" label="Criado em">
            <Input />
          </Form.Item>
        </div>
        <div className="grid w-full grid-cols-2 gap-5">
          <Form.Item<ViewStructuredDocumentType> label="Atualizado por">
            {updated_by ? inputUserBy(updated_by) : ""}
          </Form.Item>
          <Form.Item<ViewStructuredDocumentType> name="updated_at" label="Atualizado em">
            <Input />
          </Form.Item>
        </div>
        <div className="grid w-full grid-cols-2 gap-5">
          <Form.Item<ViewStructuredDocumentType> hidden={deleted_by.name ? false : true} label="Deletado por">
            {deleted_by ? inputUserBy(deleted_by) : ""}
          </Form.Item>
          <Form.Item<ViewStructuredDocumentType>
            name="deleted_at"
            hidden={deleted_by.name ? false : true}
            label="Deletado em"
          >
            <Input />
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
}

export default TabData;
