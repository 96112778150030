import { useContext, useEffect, useState } from "react";
import { Button, Form, Space, Spin, Tabs } from "antd";
import { Icon } from "@iconify/react";
import D3Can from "@components/shared/D3Can";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { useDocumentTypesStore } from "../../../store";
import { CreateDocumentTypeType, ListTypesType } from "../../../types";
import CreateData from "../CreateData";
import CreateIndex from "../CreateIndex";
import SweetAlert from "@components/shared/SweetAlert";

export const TabCreate = () => {
  const { createDocumentType, getActiveTab, setActiveTab, payload, resetStateDrawer, getIndexTypesForList } =
    useDocumentTypesStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<CreateDocumentTypeType>();
  const updateFrequencyType = Form.useWatch("temporality_permanent_guard", form);
  const [loading, setLoading] = useState(true);
  const [listInputs, SetListInputs] = useState<ListTypesType[]>([]);

  useEffect(() => {
    (async () => {
      const result = await getIndexTypesForList();
      SetListInputs(result);
      setLoading(false);
    })();
    return () => {
      resetStateDrawer();
    };
  }, [getIndexTypesForList]);
  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinishFailed={({ values, errorFields }) => {
            if (errorFields.length > 0) {
              setActiveTab("1");
              SweetAlert({ title: "Por favor, preencha os campos em vermelho para prosseguir.", type: "error" });
            }
          }}
          onFinish={async (payloads: CreateDocumentTypeType) => {
            setLoading(true);
            const hasSelectFilled =
              payload.filter((item) => item.system_index_type_id === 6 && item.list_of_key_and_value.length === 0)
                .length > 0;
            if (payload.length === 0) {
              SweetAlert({
                title:
                  "Para prosseguir, é essencial incluir pelo menos um indexador. Por favor, acesse a aba 'Indexadores' e adicione.",
                type: "error",
                timer: 5,
              });
            } else if (hasSelectFilled) {
              SweetAlert({
                title:
                  "Para prosseguir, é essencial incluir pelo menos uma opção no campo de seleção. Por favor, acesse a aba 'Indexadores' e adicione.",
                type: "error",
                timer: 5,
              });
            } else {
              if (payloads.temporality_permanent_guard) {
                payloads.temporality_year = 0;
                payloads.is_temporality_mandatory = false;
              }
              payloads.document_type_indexes = payload;
              const result = await createDocumentType(payloads);
              if (result.status === "success") {
                showMessage(result, "Tipo de Documento cadastrado com sucesso.");
                onClose();
              } else {
                showMessage(result);
              }
            }

            setLoading(false);
          }}
          autoComplete="off"
        >
          <Tabs
            tabPosition="top"
            defaultActiveKey="1"
            activeKey={getActiveTab()}
            onTabClick={(tab) => {
              setActiveTab(tab);
              if (tab === "1") {
                setSize(600);
              } else {
                setSize(1500);
              }
            }}
            className="flex h-full overflow-hidden select-none"
            items={[
              {
                key: "1",
                label: (
                  <Button className="flex items-center" type="link" icon={<Icon icon="ri:profile-line" />} size="small">
                    Dados
                  </Button>
                ),
                children: <CreateData uft={updateFrequencyType} form={form} />,
              },
              {
                key: "2",
                label: (
                  <Button
                    className="flex items-center"
                    type="link"
                    icon={<Icon icon="dashicons:index-card" />}
                    size="small"
                  >
                    Indexadores
                  </Button>
                ),
                children: <CreateIndex inputs={listInputs} />,
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="customer_document_type">
                  <Button type="primary" htmlType="submit">
                    Cadastrar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
