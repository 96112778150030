import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { diff } from "deep-diff";
import { create } from "zustand";
import { EditApiResultType, ListType, PriceSettingType, SavePriceSettingsType } from "./types";
import SweetAlert from "@components/shared/SweetAlert";

type UseCustomerGroupRepositoryTypeStoreType = {
  apiResult: ListType;
  oldApiResult: ListType;
  loading: boolean;
  loadingSave: boolean;
  alteredRepositoryTypes: string[];
  loadItemsList: () => Promise<Success<ListType> | BadRequest | StandardError>;
  savePriceSettings: () => Promise<Success<void> | BadRequest | StandardError | void>;
  editApiResult: (objs: EditApiResultType[]) => void;
  payloadGenerator: (alteredRepositoryTypes: string[]) => SavePriceSettingsType | void;
  resetState: () => void;
};

const defaultState = {
  loading: false,
  loadingSave: false,
  apiResult: {
    data: [],
  },
  oldApiResult: {
    data: [],
  },
  alteredRepositoryTypes: [],
};

export const useCustomerGroupRepositoryTypeStore = create<UseCustomerGroupRepositoryTypeStoreType>((set, get) => ({
  ...defaultState,

  resetState: () => set(defaultState),

  loadItemsList: async (): Promise<Success<ListType> | BadRequest | StandardError> => {
    set({ loading: true });
    return await new ClientHttp().get<Success<ListType>, BadRequest | StandardError>(
      "/api/v1/customer-group/repository-types",
      (result: Success<ListType>) => {
        const { status, body } = result;
        if (status !== "success") {
          set({
            apiResult: body,
            loading: false,
          });
          get().loadItemsList();
        } else {
          set({
            apiResult: body,
            oldApiResult: body,
            loading: false,
          });
        }
      },
      (error: BadRequest | StandardError) => {
        showMessage(error);
      }
    );
  },

  editApiResult: (objs: EditApiResultType[]) => {
    set({
      apiResult: {
        data: get().apiResult.data.map((item) => {
          const objsToUpdate = objs.filter((obj) => obj.repository_type_cuid === item.repository_type_cuid);
          if (objsToUpdate.length > 0) {
            let updatedItem = { ...item };
            objsToUpdate.forEach((objToUpdate) => {
              if (objToUpdate.field in updatedItem) {
                updatedItem = { ...updatedItem, [objToUpdate.field]: objToUpdate.value };
              }
            });
            return updatedItem;
          }
          return item;
        }),
      },
    });

    const oldData = get().oldApiResult.data;
    const newData = get().apiResult.data;
    const listCuids = get().alteredRepositoryTypes;
    newData.forEach((newItem, index) => {
      const differences = diff(oldData[index], newItem);
      if (differences && !listCuids.includes(newItem.repository_type_cuid)) {
        set({
          alteredRepositoryTypes: [...listCuids, newItem.repository_type_cuid],
        });
      } else if (!differences && listCuids.includes(newItem.repository_type_cuid)) {
        set({
          alteredRepositoryTypes: listCuids.filter((item) => item !== newItem.repository_type_cuid),
        });
      }
    });
  },

  payloadGenerator: (alteredRepositoryTypes: string[]): SavePriceSettingsType | void => {
    const data = get().apiResult.data;
    let hasValidationError = false;

    const payloads = alteredRepositoryTypes.map((repositoryTypeCuid) => {
      const rowData = data.find((item) => item.repository_type_cuid === repositoryTypeCuid);

      if (!rowData) {
        hasValidationError = true;
        return null;
      }

      const { apply_default_price, price } = rowData;
      if (apply_default_price === false && (price === null || price === undefined)) {
        hasValidationError = true;
        return null;
      }

      return {
        repository_type_cuid: rowData.repository_type_cuid,
        apply_default_price: rowData.apply_default_price,
        price: rowData.price,
        active: rowData.active,
      };
    });

    if (hasValidationError) {
      set({
        loadingSave: false,
      });
      SweetAlert({title: "Por favor, preencha os campos destacados em vermelho.", type: "error", timer: 5})
    }

    const result = payloads.filter((payload): payload is PriceSettingType => payload !== null);

    return {
      customer_group_repository_type_setup: result,
    };
  },

  savePriceSettings: async (): Promise<Success<void> | BadRequest | StandardError | void> => {
    set({ loadingSave: true });
    const alteredRepositoryTypes = get().alteredRepositoryTypes;
    const payload = get().payloadGenerator(alteredRepositoryTypes);
    if (payload && payload.customer_group_repository_type_setup.length > 0) {
      await new ClientHttp().post<SavePriceSettingsType, Success<void>, BadRequest | StandardError>(
        `/api/v1/customer-group/repository-types/price-setup`,
        payload,
        (result: Success<void>) => {
          showMessage(
            result,
            `${alteredRepositoryTypes.length > 1 ? "Alterações salvas" : "Alteração salva"} com sucesso.`
          );
          set({
            alteredRepositoryTypes: [],
            loadingSave: false,
          });
        },
        (error: StandardError | BadRequest) => {
          showMessage(error);
          set({ loadingSave: false });
        }
      );
    }
  },
}));
