import { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Space, Spin, Tabs } from "antd";
import { D3DrawerContext } from "@provider/D3DrawerContext";
import showMessage from "@utils/showMessage";
import { useCompanyUsersStore } from "../store";
import { SelectPermissionsGroupItemType, UpdateCompanyUserType, ViewCompanyUserType } from "../types";
import { Icon } from "@iconify/react";
import D3PermCheckTable from "@components/shared/D3PermCheckTable/D3PermCheckTable";
import D3Can from "@components/shared/D3Can";
import Selector from "@components/shared/Selector";

type UpdateCompanyUserProps = {
  cuid: string;
};

export const UpdateCompanyUserForm = ({ cuid }: UpdateCompanyUserProps) => {
  const { updateCompanyUser, loadingUpdate, getCompanyUser, getPermissionsGroupForSelect } = useCompanyUsersStore();
  const { onClose, setSize } = useContext(D3DrawerContext);
  const [form] = Form.useForm<UpdateCompanyUserType>();
  const [permissionGroups, setPermissionGroups] = useState<SelectPermissionsGroupItemType[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const [getPermissionsGroupForSelectResult, getCompanyUserResult] = await Promise.all([
        getPermissionsGroupForSelect(),
        getCompanyUser(cuid),
      ]);

      if (getCompanyUserResult.status === "success") {
        const viewCompanyUser = getCompanyUserResult.body as ViewCompanyUserType;

        form.setFieldsValue({
          name: viewCompanyUser.name,
          email: viewCompanyUser.email,
          username: viewCompanyUser.username,
          permissions: viewCompanyUser.permissions ?? [],
          company_permissions_groups_cuids: viewCompanyUser.company_permissions_groups.map((group) => group.cuid),
        });

        setPermissionGroups(getPermissionsGroupForSelectResult);
        setLoading(false);
      } else {
        showMessage(getCompanyUserResult);
      }
    })();
  }, [cuid, form, getCompanyUser, getPermissionsGroupForSelect]);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          className="select-none"
          form={form}
          layout="vertical"
          onFinish={async (payload: UpdateCompanyUserType) => {
            const payloadUp = {
              ...payload,
              permissions: form.getFieldValue("permissions"),
            };
            const isUpdated = await updateCompanyUser(cuid, payloadUp);
            if (isUpdated) {
              onClose();
            }
          }}
          autoComplete="off"
        >
          <Tabs
            defaultActiveKey="1"
            className="select-none"
            onTabClick={(tab) => (tab === "1" ? setSize(600) : setSize(1200))}
            items={[
              {
                key: "1",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="fluent:form-24-filled" className="w-5 h-5" />
                    Dados
                  </span>
                ),
                children: (
                  <>
                    <Form.Item<UpdateCompanyUserType> name="name" label="Nome" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>

                    <Form.Item<UpdateCompanyUserType>
                      label="Usuário"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira o usuário.",
                        },
                        {
                          max: 30,
                          message: "O usuário deve ter menos de 30 caracteres.",
                        },
                        {
                          validator: (_, value) =>
                            /\s/.test(value)
                              ? Promise.reject(new Error("O usuário não pode conter espaços."))
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item<UpdateCompanyUserType>
                      label="E-mail"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, insira o e-mail.",
                        },
                        {
                          type: "email",
                          message: "Por favor, insira um e-mail válido.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item<UpdateCompanyUserType>
                      label="Grupos de Permissões"
                      name="company_permissions_groups_cuids"
                    >
                      <Selector
                        fieldMap={{
                          status: "active",
                          value: "value",
                          label: "label",
                          dataLabel: ["label"],
                          dataFilter: ["label"],
                        }}
                        mode="multiple"
                        options={permissionGroups}
                      />
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "w",
                label: (
                  <span className="flex items-center justify-center gap-2 ">
                    <Icon icon="icon-park-outline:permissions" className="w-[18px] h-[18px]" />
                    Permissões
                  </span>
                ),
                children: (
                  <Form.Item<UpdateCompanyUserType> label="Permissões do usuário" name="permissions">
                    <D3PermCheckTable
                      profile="CPN"
                      onChange={(newValues: string[]) => {
                        form.setFieldValue("permissions", newValues);
                      }}
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
          <Form.Item>
            <div className="text-right">
              <Space size="small">
                <Button disabled={loadingUpdate} type="default" danger onClick={() => onClose()}>
                  Cancelar
                </Button>
                <D3Can action="update" resource="company_user">
                  <Button loading={loadingUpdate} type="primary" htmlType="submit">
                    Atualizar
                  </Button>
                </D3Can>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};
