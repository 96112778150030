import { BadRequest, ClientHttp, StandardError, Success } from "@utils/clientHttp";
import showMessage from "@utils/showMessage";
import { create } from "zustand";
import {
  IndexItemType,
  ItemDocumentTypeType,
  ItemListServicesAvailableForCustomerType,
  ItemSimpleListAddressesType,
  ItemSimpleListUsersCustomer,
  ListIndexesType,
  ListPhysicalDocuments,
  ListServicesAvailableForCustomerType,
  PayloadType,
  PhysicalDocumentItemType,
  SearchPhysicalDocumentType,
  SimpleListAddressesType,
  SimpleListDepartmentType,
  SimpleListDocumentTypeType,
  SimpleListUsersCustomer,
} from "./types";
import { ItemDepartmentType } from "../components/form-upload-work/types";

type SpecialStoreState = {
  filter: SearchPhysicalDocumentType;
  apiResult: ListPhysicalDocuments;
  loadPhysicalDocument: () => Promise<Success<ListPhysicalDocuments> | BadRequest | StandardError>;
  setPagination: (page: number, perPage: number) => void;
  simpleListDocumentType: () => Promise<void>;
  documentsType: ItemDocumentTypeType[];
  getListIndexesByDocumentTypeCuid: (documentTypeCuid: string) => Promise<void>;
  loadingIndexes: boolean;
  loading: boolean;
  listIndexers: IndexItemType[];
  resetState: () => void;
  setFilter: (filter: SearchPhysicalDocumentType) => void;
  resetFilter: () => void;
  simpleListDepartmentCustomer: () => Promise<void>;
  departments: ItemDepartmentType[];
  loadingDepartments: boolean;
  selectedRows: PhysicalDocumentItemType[];
  documentsSelected: PhysicalDocumentItemType[];
  listServicesAvailableForCustomer: () => Promise<void>;
  listServicesAvailable: ItemListServicesAvailableForCustomerType[];
  loadingListServices: boolean;
  serviceSelected: ItemListServicesAvailableForCustomerType;
  simpleListUsersCustomer: () => Promise<void>;
  users: ItemSimpleListUsersCustomer[];
  loadingUsers: boolean;
  simpleListAddressesCustomer: () => Promise<void>;
  addresses: ItemSimpleListAddressesType[];
  loadingAddresses: boolean;
  payload: PayloadType;
};

const defaultState = {
  apiResult: {
    data: [],
    total: 0,
  },
  filter: {
    config: {
      pagination: {
        page: 1,
        per_page: 10,
      },
      sort: {
        by: "fs_document_id",
        direction: "desc",
      },
    },
    filter_document: {
      created_at: {
        end_date: "",
        start_date: "",
        option: "",
      },
      document_physical_tag_id: [],
      document_range: {
        end_document_physical_tag_id: "",
        start_document_physical_tag_id: "",
      },
      expected_purge_date: {
        end_date: "",
        start_date: "",
        option: "",
      },
      name: "",
      purged_at: {
        end_date: "",
        start_date: "",
        option: "",
      },
      show_index: false,
      status: [],
    },
    filter_document_type: {
      document_type_cuid: "",
      indexes_values: [],
    },
    filter_order: {
      deployments: [],
      services: [],
    },
    filter_repository: {
      created_at: {
        end_date: "",
        start_date: "",
        option: "",
      },
      departments_cuids: [],
      origin: "",
      position: "",
      repository_physical_tag_id: [],
      repository_range: {
        end_repository_physical_tag_id: "",
        start_repository_physical_tag_id: "",
      },
      repository_types_cuids: [],
    },
    filter_rule: {
      department_cuid: "",
      document_physical_tag_id: [],
      status: "",
    },
  },
  loading: false,
  listIndexers: [],
  loadingIndexes: false,
  departments: [],
  loadingDepartments: false,
  documentsType: [],
  selectedRows: [],
  documentsSelected: [],
  listServicesAvailable: [],
  loadingListServices: false,
  serviceSelected: {} as ItemListServicesAvailableForCustomerType,
  users: [],
  loadingUsers: false,
  addresses: [],
  loadingAddresses: false,
  payload: {
    service_cuid: "",
    department_cuid: "",
    requester_user_cuid: "",
    requester_person_name: "",
    requester_person_email: "",
    email: "",
    doc_status: "",
    observation: "",
    fs_documents: [],
  },
};

export const useSpecialsStore = create<SpecialStoreState>((set, get) => ({
  ...defaultState,
  resetState: () => set(defaultState),
  setFilter: (filter: SearchPhysicalDocumentType) => {
    const oldFilter = get().filter;
    set({
      filter: {
        ...oldFilter,
        ...filter,
        config: {
          ...oldFilter.config,
          pagination: {
            page: 1,
            per_page: 10,
          },
        },
      },
    });
    get().loadPhysicalDocument();
  },
  resetFilter: () => {
    set({ filter: defaultState.filter });
    get().loadPhysicalDocument();
  },
  setPagination: (page: number, perPage: number) => {
    const filter = get().filter;
    useSpecialsStore.setState({
      filter: {
        ...filter,
        config: {
          ...filter.config,
          pagination: {
            page: page,
            per_page: perPage,
          },
        },
      },
    });
    get().loadPhysicalDocument();
  },
  loadPhysicalDocument: async (): Promise<Success<ListPhysicalDocuments> | BadRequest | StandardError> => {
    set({ loading: true });
    return await new ClientHttp().post<
      SearchPhysicalDocumentType,
      Success<ListPhysicalDocuments>,
      BadRequest | StandardError
    >(
      `/api/v1/orders/services/documents`,
      get().filter,
      (result: Success<ListPhysicalDocuments>): void => {
        const {
          body: { data, total },
        } = result;
        set({
          apiResult: {
            data,
            total,
          },
          loading: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loading: false });
      }
    );
  },

  simpleListDocumentType: async (): Promise<void> => {
    await new ClientHttp().getItensForSelect<Success<SimpleListDocumentTypeType>, StandardError, void>(
      "/api/v1/customer/document-types/simple-list",
      (result: Success<SimpleListDocumentTypeType>): void => {
        set({
          documentsType: result.body.data,
        });
      },
      (error: StandardError): void => {
        showMessage(error, "Erro ao carregar lista de tipos de documento.");
      }
    );
  },
  getListIndexesByDocumentTypeCuid: async (documentTypeCuid: string): Promise<void> => {
    set({ loadingIndexes: true });
    await new ClientHttp().getItensForSelect<Success<ListIndexesType>, StandardError, void>(
      `/api/v1/customer/document-types/${documentTypeCuid}/document-type-indexes`,
      (result: Success<ListIndexesType>): void => {
        set({
          listIndexers: result.body.data as IndexItemType[],
          loadingIndexes: false,
        });
      },
      (error: StandardError): void => {
        showMessage(error, "Erro ao carregar lista de tipos de documento.");
        set({
          loadingIndexes: false,
        });
      }
    );
  },
  simpleListDepartmentCustomer: async (): Promise<void> => {
    set({ loadingDepartments: true });
    await new ClientHttp().getItensForSelect<Success<SimpleListDepartmentType>, StandardError, void>(
      "/api/v1/customer/departments/simple-list",
      (result: Success<SimpleListDepartmentType>): void => {
        set({
          departments: result.body.data,
          loadingDepartments: false,
        });
      },
      (error: StandardError): void => {
        showMessage(error);
        set({ loadingDepartments: false });
      }
    );
  },
  listServicesAvailableForCustomer: async (): Promise<void> => {
    set({ loadingListServices: true });
    const docStatus = get().filter.filter_rule.status;
    await new ClientHttp().get<Success<ListServicesAvailableForCustomerType>, BadRequest | StandardError>(
      `/api/v1/orders/services/${docStatus}/services`,
      (result: Success<ListServicesAvailableForCustomerType>): void => {
        set({
          listServicesAvailable: result.body.data,
          loadingListServices: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingListServices: false });
      }
    );
  },
  simpleListUsersCustomer: async (): Promise<void> => {
    set({ loadingUsers: true });
    await new ClientHttp().get<Success<SimpleListUsersCustomer>, BadRequest | StandardError>(
      "/api/v1/customer/users/simple-list",
      (result: Success<SimpleListUsersCustomer>): void => {
        set({
          users: result.body.data,
          loadingUsers: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingUsers: false });
      }
    );
  },
  simpleListAddressesCustomer: async (): Promise<void> => {
    set({ loadingAddresses: true });
    await new ClientHttp().get<Success<SimpleListAddressesType>, BadRequest | StandardError>(
      "/api/v1/customer/addresses/simple-list",
      (result: Success<SimpleListAddressesType>): void => {
        set({
          addresses: result.body.data,
          loadingAddresses: false,
        });
      },
      (error: BadRequest | StandardError): void => {
        showMessage(error);
        set({ loadingAddresses: false });
      }
    );
  },
}));
