import { Select } from "antd";
import { SelectProps as AntdSelectProps } from "antd/es/select";
import { Icon } from "@iconify/react";
import StatusSelect from "@components/shared/StatusSelect";

type OptionsType = {
  value: string;
  label: string;
  status?: string;
  description?: string;
  dataFilter: string[];
  dataLabel: string[];
  disabled?: boolean;
};

interface OptionItem {
  [key: string]: any;
}

export interface SelectProps extends AntdSelectProps<string[] | string> {
  options: OptionItem[];
  fieldMap: OptionsType;
}

/**
 * Componente Selector/Select .
 *
 * @param props.fieldMap - Converta seus dados do endpoint para selector.
 * @returns JSX.Element - O elemento JSX do componente.
 *
 * @example
 * ```tsx
 *  <Selector
 *         fieldMap={{
 *           status: "active",
 *           value: "cuid",
 *           label: "description",
 *           description: "abbreviation",
 *           dataLabel: ["description", "abbreviation"],
 *           dataFilter: ["cuid", "description", "abbreviation"],
 *           disabled: true
 *         }}
 *         mode="multiple"
 *         options={options}
 *         // props do Select antd
 *       />
 * ```
 */

function Selector({ options, fieldMap, value, onChange, ...props }: SelectProps) {
  const handleChange = (newValue: string[] | string) => {
    if (props.mode === "multiple") {
      const valuesArray = typeof newValue === "string" ? [newValue] : newValue;

      if (valuesArray.includes("all")) {
        if (options.length === value?.length) {
          onChange?.([], []);
        } else {
          const allValues = options.map((option) => option[fieldMap.value]);
          onChange?.(allValues, []);
        }
      } else {
        onChange?.(valuesArray, []);
      }
    } else {
      onChange?.(newValue, []);
    }
  };

  return (
    <Select
      {...props}
      value={value}
      onChange={handleChange}
      allowClear
      listItemHeight={0}
      listHeight={260}
      removeIcon={
        <Icon
          icon="iconamoon:close-duotone"
          className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
        />
      }
      menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
      showSearch
      optionLabelProp="label"
      maxTagCount="responsive"
      className="w-full truncate select-none"
      optionFilterProp="children"
      filterOption={(input, option) => (option?.dataFilter as string)?.toLowerCase().includes(input.toLowerCase())}
    >
      {props.mode === "multiple" && (
        <Select.Option key="all" value="all" className="border-[1px] border-gray-200 bg-gray-50">
          <span className="flex items-center justify-center w-full text-sm font-medium ">
            {options.length === value?.length ? "DESMARCAR TODOS" : "SELECIONAR TODOS"}
          </span>
        </Select.Option>
      )}

      {options.map((option) => {
        const status = fieldMap.status ? option[fieldMap.status] : undefined;
        const value = option[fieldMap.value];
        const label = option[fieldMap.label];
        const description = fieldMap.description ? option[fieldMap.description] : undefined;
        const dataLabel = fieldMap.dataLabel.map((key) => option[key]).join(" - ");
        const dataFilter = fieldMap.dataFilter.map((key) => option[key]).join(" ");
        const disabled = fieldMap.disabled ? (fieldMap.status ? !option[fieldMap.status] : false) : false;
        return (
          <Select.Option key={value} disabled={disabled} value={value} dataFilter={dataFilter} label={dataLabel}>
            <div className="flex items-center justify-between ">
              <div className="flex flex-col truncate">
                <span className="truncate">{label}</span>
                {description && <span className="text-[10px] truncate">{description}</span>}
              </div>
              {status !== undefined && <StatusSelect status={status} />}
            </div>
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default Selector;
