import D3Can from "@components/shared/D3Can";
import { Icon } from "@iconify/react";
import D3DateHourSorter from "@utils/D3DateHourSorter";
import { Badge, Button, Form, Grid, Pagination, Popover, Select, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRelocationStore } from "../store";
import { CreateRelocationType, ItemSimpleListeRepositoriesType } from "../types";
import { SearchRepositoriesForm } from "./SearchRepositoriesForm";
import StatusSelect from "@components/shared/StatusSelect";
import SweetAlert from "@components/shared/SweetAlert";

const { useBreakpoint } = Grid;
enum Status {
  STO = "Armazenado",
  REG = "Cadastrado",
  PUR = "Expurgado",
  DEL = "Deletado",
  MIG = "Em migração",
  REQ = "Solicitado",
  PRO = "Em processamento",
  INC = "No cliente",
}
export const CreateRelocationForm = () => {
  const [form] = Form.useForm<CreateRelocationType>();
  const {
    simpleListRepositoryType,
    repositoryTypes,
    createRelocation,
    selectedRows,
    listRepositoriesSuitable,
    repositoriesSuitable,
    loadingRepositoriesSuitable,
    totalRepositoriesSuitable,
    paginationRepositories,
    setPaginationRepositories,
    resetFilterRepositories,
  } = useRelocationStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [openSearch, setOpenSearch] = useState(false);
  const screens = useBreakpoint();

  const isSelected = Form.useWatch("repository_type_cuid", form) ? true : false;

  useEffect(() => {
    (async () => {
      await simpleListRepositoryType();
      setLoading(false);
    })();
  }, [simpleListRepositoryType]);

  return (
    <div className="flex h-full mb-1 mr-1 overflow-hidden bg-white rounded shadow">
      <Form
        className="flex w-full h-full select-none"
        form={form}
        layout="vertical"
        onFinish={async (payload: CreateRelocationType) => {
          setLoading(true);
          if (selectedRows.length > 0) {
            payload.repositories_ids = selectedRows;
            const result = await createRelocation(payload);
            if (result) {
              navigate(-1);
            }
          } else {
            SweetAlert({
              title: "Para finalizar o processo, selecione pelo menos um tipo de repositório.",
              type: "error",
            });
          }
          setLoading(false);
        }}
        onValuesChange={async (changedValues: any, values: CreateRelocationType) => {
          if (changedValues.repository_type_cuid) {
            useRelocationStore.setState({
              repositoriesSuitable: [],
            });
            await listRepositoriesSuitable(changedValues.repository_type_cuid);
          }
        }}
        autoComplete="off"
      >
        <div className="flex flex-col w-full h-full overflow-hidden">
          <div className="grid grid-cols-3 items-center justify-center w-full h-[55px] border-b border-gray-200 min-h-[55px]">
            <div className="flex justify-start px-2">
              <Button
                className="flex items-center justify-center"
                icon={<Icon height={18} icon="ion:arrow-back-outline" />}
                type="dashed"
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </div>
            <div className="flex justify-center">
              <span className="text-xl font-semibold text-gray-600">Cadastrar Ordem de Remanejamento</span>
            </div>
            <div></div>
          </div>

          <div className="flex w-full h-full p-2 overflow-hidden">
            <Table
              rowKey="id"
              title={() => (
                <div className="grid items-center w-full grid-cols-3 ">
                  <div className="flex gap-4 w-96">
                    <Popover
                      open={openSearch}
                      placement="rightTop"
                      title="Pesquisar repositórios"
                      content={
                        <SearchRepositoriesForm
                          onCallBackFilter={() => setOpenSearch(false)}
                          onCallBackResetFilter={() => {
                            setOpenSearch(false);
                            resetFilterRepositories();
                          }}
                        />
                      }
                      trigger="click"
                      onOpenChange={(open) => setOpenSearch(open)}
                    >
                      <Button
                        disabled={!isSelected}
                        className={screens.md ? "flex justify-center items-center" : ""}
                        onClick={() => setOpenSearch(!openSearch)}
                        type="primary"
                        size="middle"
                        icon={<Icon icon="fluent:search-12-filled" height={16} width={16} />}
                      >
                        {screens.md ? "Pesquisar" : null}
                      </Button>
                    </Popover>

                    <Form.Item<CreateRelocationType>
                      name="repository_type_cuid"
                      className="p-0 m-0 w-96 max-h-8"
                      rules={[{ required: true, message: "Por favor, selecione o tipo de repositório." }]}
                    >
                      <Select
                        loading={loading}
                        disabled={loading || selectedRows.length > 0}
                        listItemHeight={10}
                        allowClear
                        placeholder="Selecione o tipo de repositório"
                        removeIcon={
                          <Icon
                            icon="iconamoon:close-duotone"
                            className="w-4 h-4 text-red-400 transition-all duration-300 ease-in-out hover:text-red-600"
                          />
                        }
                        menuItemSelectedIcon={<Icon icon="eva:checkmark-outline" className="w-5 h-5 text-blue-500" />}
                        optionLabelProp="dataLabel"
                        showSearch
                        maxTagCount="responsive"
                        size="middle"
                        className="w-full truncate select-none"
                        optionFilterProp="children"
                        filterOption={(input, option) => option?.dataFilter.toLowerCase().includes(input.toLowerCase())}
                        onClear={() => useRelocationStore.setState({ repositoriesSuitable: [] })}
                      >
                        {repositoryTypes.map(({ active, cuid, description }) => (
                          <Select.Option
                            disabled={!active}
                            key={cuid}
                            value={cuid}
                            dataFilter={description}
                            dataLabel={description}
                          >
                            <div className="flex items-center justify-between ">
                              <div className="flex flex-col truncate">
                                <span className="truncate">{description}</span>
                              </div>
                              <StatusSelect status={active} />
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="flex items-center justify-center">
                    <span className="text-base font-semibold text-gray-600">Lista de Repositórios</span>
                  </div>
                  <div className="flex justify-end pr-3">
                    <Badge size="small" count={showSelected ? repositoriesSuitable.length : selectedRows.length}>
                      <Button
                        onClick={() => setShowSelected(!showSelected)}
                        disabled={selectedRows.length === 0 && !showSelected}
                        type="primary"
                      >
                        {`Exibir ${showSelected ? "todos" : selectedRows.length > 1 ? "selecionados" : "selecionado"}`}
                      </Button>
                    </Badge>
                  </div>
                </div>
              )}
              footer={() => (
                <div className="flex items-center justify-between w-full">
                  <Badge size="small" count={selectedRows.length}>
                    <Button
                      onClick={() =>
                        useRelocationStore.setState({
                          selectedRows: [],
                        })
                      }
                      danger
                      disabled={selectedRows.length === 0}
                      type="dashed"
                    >
                      Remover todos selecionados
                    </Button>
                  </Badge>

                  <Pagination
                    disabled={totalRepositoriesSuitable === 0}
                    showLessItems
                    showTotal={(total, range) =>
                      `Exibindo de ${range[0]} a ${range[1]} do total ${total} ${total > 1 ? "registros" : "registro"}`
                    }
                    showSizeChanger={true}
                    current={paginationRepositories.page}
                    total={totalRepositoriesSuitable}
                    pageSize={paginationRepositories.pageSize}
                    onChange={(page, pageSize) => setPaginationRepositories(page, pageSize)}
                  />
                </div>
              )}
              className="w-full d3-table-h-full h-footer"
              pagination={false}
              loading={loadingRepositoriesSuitable}
              bordered
              size="small"
              dataSource={
                showSelected
                  ? repositoriesSuitable.filter((item) => selectedRows.includes(item.id))
                  : repositoriesSuitable
              }
              rowSelection={{
                hideSelectAll: false,
                type: "checkbox",
                selectedRowKeys: selectedRows,
                onChange: (_, selectRows: ItemSimpleListeRepositoriesType[]) => {
                  useRelocationStore.setState({
                    selectedRows: selectRows.map((repo) => repo.id),
                  });
                },
              }}
            >
              <Table.Column<ItemSimpleListeRepositoriesType>
                showSorterTooltip={false}
                key="repository_physical_tag_id"
                title="Código da etiqueta"
                dataIndex="repository_physical_tag_id"
              />
              <Table.Column<ItemSimpleListeRepositoriesType>
                showSorterTooltip={false}
                key="billable_department"
                title="Departamento"
                dataIndex={["billable_department", "description"]}
              />
              <Table.Column<ItemSimpleListeRepositoriesType>
                showSorterTooltip={false}
                key="repository_type"
                title="Tipo de repositório"
                dataIndex={["repository_type", "description"]}
              />
              <Table.Column<ItemSimpleListeRepositoriesType>
                showSorterTooltip={false}
                key="number_of_documents"
                title="Qtd. documentos"
                dataIndex="number_of_documents"
              />
              <Table.Column<ItemSimpleListeRepositoriesType>
                showSorterTooltip={false}
                key="created_by"
                title="Criado por"
                dataIndex="created_by"
              />
              <Table.Column<ItemSimpleListeRepositoriesType>
                showSorterTooltip={false}
                key="created_at"
                title="Criado em"
                dataIndex="created_at"
                render={(value, record) => D3DateHourSorter(record.created_at)}
              />
              <Table.Column<ItemSimpleListeRepositoriesType>
                showSorterTooltip={false}
                key="status"
                title="Status"
                dataIndex="status"
                render={(value, record) => <span>{Status[record.status as keyof typeof Status]}</span>}
              />
            </Table>
          </div>
          <div className="flex items-center justify-end w-full px-2 border-t h-[55px] min-h-[55px] border-gray-200 ">
            <Form.Item className="p-0 m-0">
              <Space size="small">
                <Button type="default" danger onClick={() => navigate(-1)}>
                  Cancelar
                </Button>
                <D3Can action="create" resource="order_relocation">
                  <Button loading={loading} type="primary" htmlType="submit">
                    Finalizar Requisição de Remanejamento
                  </Button>
                </D3Can>
              </Space>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};
