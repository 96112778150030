import D3PDFView from "@components/shared/D3PDF/D3PDFView";
import showMessage from "@utils/showMessage";
import { Drawer } from "antd";
import { useEffect, useState } from "react";
import FileInfo from "./components/FileInfo";
import { ViewFileType } from "../types";
import useFileStore from "../store";
import SpinLoading from "@components/shared/SpinLoading";
import RenderFile from "@components/shared/RenderFile";
import SweetAlert from "@components/shared/SweetAlert";

type ViewFilePropsType = {
  fsFileCuid: string;
};

function ViewFile({ fsFileCuid }: ViewFilePropsType) {
  const { viewFile } = useFileStore();
  const [file, setFile] = useState<ViewFileType>({} as ViewFileType);
  const [loading, setLoading] = useState<boolean>(true);
  const [showInfo, setShowInfo] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const result = await viewFile(fsFileCuid);
        if (result.code === 200) {
          setFile(result.body as ViewFileType);
        } else {
          showMessage(result, "Impossível carregar o arquivo.");
        }
      } catch (error) {
        SweetAlert({
          title: "Impossível carregar a url do arquivo.",
          type: "error",
        });
      }
    })();
    setLoading(false);
  }, [fsFileCuid, viewFile]);

  return loading && !file ? (
    <div className="flex items-center justify-center h-full">
      <SpinLoading />
    </div>
  ) : (
    <>
      {file.extension?.key?.replace(".", "") === "pdf" ? (
        <D3PDFView
          key={file.cuid}
          info
          edit={fsFileCuid}
          showInfo={() => setShowInfo(!showInfo)}
          fileName={file.name}
          url={file.url}
        />
      ) : (
        <RenderFile key={file.cuid} fileType={file.extension?.key?.replace(".", "")} uri={file.url} />
      )}
      <Drawer
        key={file.cuid}
        title="Informações do arquivo"
        width={600}
        closable
        open={showInfo}
        onClose={() => setShowInfo(false)}
      >
        <FileInfo key={file.cuid} info={file} />
      </Drawer>
    </>
  );
}

export default ViewFile;
